<script setup lang="ts">
import { ref, reactive } from "vue";
import axios from "axios";

import { getSeoObject } from "~/helpers/seo";

const { data } = useCustomFetch("/api/strapi/login-page");

// useSeoMeta(getSeoObject(data.value?.seo));

const formData = reactive({
  email: "",
  password: "",
  rememberMe: false,
});

const errors = reactive({
  email: "",
  password: "",
});

const errorMessage = ref(false);
const submitButton = ref<HTMLElement | null>(null);

const validateForm = () => {
  let valid = true;

  // Reset errors
  errors.email = "";
  errors.password = "";

  // Validate email
  if (!formData.email) {
    errors.email =
      data.value?.validEmailText || "Please enter a valid email address";
    valid = false;
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
  ) {
    errors.email =
      data.value?.validEmailText || "Please enter a valid email address";
    valid = false;
  }

  // Validate password
  if (!formData.password) {
    errors.password =
      data.value?.passwordRequiredText || "Password is required to login";
    valid = false;
  }

  return valid;
};

const submitForm = async () => {
  if (!submitButton.value) return;

  if (!validateForm()) return;

  submitButton.value.disabled = true;
  submitButton.value.classList.add("disabled");

  const requestBody = {
    service_id: 1,
    email: formData.email,
    password: formData.password,
  };

  try {
    const response = await axios.post(
      "https://24markets.com/workers/api/middleware-crm/syntellicore/login",
      requestBody,
    );
    if (response.data.success) {
      window.location.href = response.data.data[0].url;
    } else {
      submitButton.value.disabled = false;
      submitButton.value.classList.remove("disabled");
      errorMessage.value = true;
    }
  } catch (error: any) {
    submitButton.value.disabled = false;
    submitButton.value.classList.remove("disabled");
    errorMessage.value = true;
  }
};
</script>

<template>
  <section v-if="data" id="maincontentblock" class="markethours">
    <SeoMeta v-if="data?.seo" v-bind="data?.seo" />
    <div class="candle" data-aos="zoom-in-up" data-aos-delay="200"></div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="title">{{ data.title }}</h2>
          <p class="titledesci">
            {{ data.description }}
          </p>
        </div>
      </div>

      <!-- login Start -->
      <div
        class="px-4 md:px-8 py-[44px] bg-white rounded-[10px] mt-[77px] box-shadow-lg mx-11 snipcss-oi5xb"
      >
        <div class="text-gray text-sm">
          <span class="rich-text">
            <p>
              {{ data.hint }}
            </p>
          </span>
        </div>
        <div
          class="grid lg:grid-rows-1 lg:grid-cols-2 grid-rows-2 grid-cols-1 lg:gap-[60px] mt-5 lg:items-center"
        >
          <div>
            <form class="form-wrapper" @submit.prevent="submitForm">
              <div class="w-full">
                <label for="email" class="text-black text-[16px] md:text-lg">
                  {{ data.emailName }}:
                  <span class="text-orange text-[16px] md:text-lg"> *</span>
                </label>
                <input
                  id="email"
                  v-model="formData.email"
                  name="email"
                  type="email"
                  :placeholder="data.emailPlaceholder"
                  required
                  class="w-full mt-4 py-[16px] px-4 rounded-[10px] bg-white border-[1px] text-black focus:ring-0 focus:outline-none focus:border-[1px] border-lightGray"
                />
                <p v-if="errors.email" class="validation">{{ errors.email }}</p>
              </div>
              <div class="mt-[26px]">
                <label for="password" class="text-black text-[16px] md:text-lg">
                  {{ data.passwordName }}:
                  <span class="text-orange text-[16px] md:text-lg"> *</span>
                </label>
                <input
                  id="password"
                  v-model="formData.password"
                  name="password"
                  type="password"
                  required
                  class="w-full mt-4 py-[16px] px-4 rounded-[10px] bg-white border-[1px] text-black focus:ring-0 focus:outline-none focus:border-[1px] border-lightGray"
                />
                <p v-if="errors.password" class="validation">
                  {{ errors.password }}
                </p>
              </div>
              <div
                class="mt-[19px] flex flex-col md:flex-row justify-between md:items-center"
              >
                <div>
                  <label>
                    <div class="flex items-center" style="cursor: pointer">
                      <input
                        v-model="formData.rememberMe"
                        name="rememberMe"
                        type="checkbox"
                        class="rounded-[5px] checked:bg-checkbox-icon bg-center border-lightGray checked:bg-blue checked:hover:bg-blue checked:focus:bg-blue bg-[length:20px_20px] border-1 w-[26px] h-[26px] focus:ring-0 focus:outline-none checked:ring-0 focus:ring-offset-0 checked:ring-offset-0 mr-4"
                      />
                      <span
                        class="block text-[16px] md:text-lg text-black pr-2"
                      >
                        {{ data.rememberMeName }}
                      </span>
                    </div>
                  </label>
                </div>
                <div>
                  <NuxtLinkLocale
                    style="color: rgb(25, 7, 135); font-size: 18px"
                    to="/forgot-password"
                  >
                    {{ data.forgotPasswordText }}
                  </NuxtLinkLocale>
                </div>
              </div>
              <button
                ref="submitButton"
                class="bg-[#1ebde2] rounded-[10px] px-11 py-6 text-white font-medium text-[16px] md:text-lg mt-12 items-center mt-40px w-full flex justify-between style-cN6o9"
                type="submit"
              >
                <span>{{ data.submitButtonText }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-10"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </button>
              <div
                v-if="errorMessage"
                id="error-message"
                style="padding-top: 10px"
              >
                <p class="validation errorRegister" style="font-size: 16px">
                  {{ data.emailOrPasswordIncorrrectText }}
                </p>
              </div>
            </form>
          </div>
          <div class="lg:mt-0 mt-6">
            <div
              class="py-[92px] px-4 md:px-8 bg-lightBlue rounded-[10px] bg-no-repeat bg-[position:left_bottom,top_right] bg-box-small"
            >
              <p
                class="text-black text-[18px] md:text-[22px] w-auto text-center"
              >
                <span class="rich-text"></span>
              </p>
              <p
                style="
                  text-align: center;
                  font-size: 22px !important;
                  line-height: 1.8rem !important;
                "
              >
                {{ data.doNotHaveAccountText }}
              </p>
              <p></p>
              <NuxtLinkLocale
                target="_self"
                class="Link_link__qbLha undefined undefined"
                href="/register"
              >
                <button
                  id="login-button"
                  class="bg-blue w-full md:w-auto rounded-[10px] px-4 md:px-11 py-6 text-white font-medium text-[16px] md:text-lg mt-12 flex items-center mx-auto"
                >
                  <span>{{ data.registerButtonText }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 ml-10"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </button>
              </NuxtLinkLocale>
            </div>
          </div>
        </div>
        <div
          class="text-gray mt-6 md:mt-16 marketsLoginForm_link_styling__Y_bOm"
          v-html="$mdRenderer.renderInline(data.bottomHint)"
        ></div>
      </div>
      <!-- login End -->
    </div>
  </section>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

.validation {
  font-size: 12px;
  color: red;
  padding: 4px;
  font-weight: 400 !important;
}

.form-wrapper {
  max-width: 800px;
}

.bg-box-small {
  background-image: url(https://stagingv2.24markets.com/lgbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-size: cover;
}

.flex {
  display: flex;
}

.bg-no-repeat {
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
}

@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:bg-center {
    background-position: 50%;
  }
}

.flex-col {
  flex-direction: column;
}

@media (min-width: 1024px) {
  .lg\:pt-\[99px\] {
    padding-top: 99px;
  }
}

body {
  margin: 0;
}

body {
  margin: 0;
}

body {
  font-family: Poppins, Arial, sans-serif;
  overflow-y: scroll;
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui;
  font-feature-settings: normal;
  font-variation-settings: normal;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.mt-\[77px\] {
  margin-top: 20px;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-\[44px\] {
  padding-top: 44px;
  padding-bottom: 44px;
}

.box-shadow-lg {
  box-shadow: 0 30px 145px 62px rgba(39, 39, 39, 0.1);
}

@media (min-width: 768px) {
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid;
}

*,
:after,
:before {
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}

*,
:before,
:after {
  box-sizing: border-box;
}

:backdrop {
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(139 151 159 / var(--tw-text-opacity));
}

.mt-5 {
  margin-top: 1.25rem;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

@media (min-width: 1024px) {
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:gap-\[60px\] {
    gap: 60px;
  }
}

.mt-6 {
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .md\:mt-16 {
    margin-top: 4rem;
  }
}

.marketsLoginForm_link_styling__Y_bOm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  .marketsLoginForm_link_styling__Y_bOm {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }
}

p {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

.rich-text p {
  margin-bottom: 0;
}

.bg-lightBlue {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[position\:left_bottom\] {
  background-position:
    0 100%,
    100% 0;
}

.py-\[92px\] {
  padding-top: 92px;
  padding-bottom: 92px;
}

@media (max-width: 768px) {
  .py-\[92px\] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .mt-12 {
    margin-top: 1rem !important;
  }

  .grid-rows-2 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .mt-\[77px\] {
    margin-top: 0 !important;
  }

  .mx-11 {
    margin: 0;
  }
}

.w-full {
  width: 100%;
}

.mt-\[26px\] {
  margin-top: 26px;
}

.mt-\[19px\] {
  margin-top: 19px;
}

.justify-between {
  justify-content: space-between;
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }
}

#minfooter {
  display: none !important;
}

button {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button {
  text-transform: none;
}

button {
  cursor: pointer;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button {
  overflow: visible;
}

[type="submit"],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.mt-12 {
  margin-top: 3rem;
}

.items-center {
  align-items: center;
}

.bg-\[\#1ebde2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 189 226 / var(--tw-bg-opacity));
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.text-\[16px\] {
  font-size: 16px;
}

.font-medium {
  font-weight: 500;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

button,
[type="submit"] {
  -webkit-appearance: button;
}

form button[type="submit"] {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(9 149 221 / var(--tw-bg-opacity));
  padding: 0.7em 1.7em;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

form button[type="submit"]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(7 116 172 / var(--tw-bg-opacity));
}

.w-auto {
  width: auto;
}

.text-center {
  text-align: center;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(51 48 65 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:text-\[22px\] {
    font-size: 22px;
  }
}

:deep(a) {
  color: inherit;
  text-decoration: inherit;
}

:deep(a) {
  background-color: transparent;
}

:deep(a) {
  color: currentColor;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.Link_link__qbLha {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

:deep(a):hover {
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.marketsLoginForm_link_styling__Y_bOm :deep(a) {
  --tw-text-opacity: 1 !important;
  color: rgb(25, 7, 135) !important;
}

form label {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

input {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input {
  overflow: visible;
}

.mt-4 {
  margin-top: 1rem;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-lightGray {
  --tw-border-opacity: 1;
  border-color: rgb(218 222 229 / var(--tw-border-opacity));
}

.py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}

[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

form [type="password"] {
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
  padding: 16px 1.111rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:mt-0 {
    margin-top: 0;
  }
}

svg {
  display: block;
  vertical-align: middle;
}

.ml-10 {
  margin-left: 2.5rem;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}

button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

button {
  -webkit-appearance: button;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: #000;
}

@media (min-width: 768px) {
  .md\:w-auto {
    width: auto;
  }

  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(255 145 0 / var(--tw-text-opacity));
}

.cursor-pointer {
  cursor: pointer;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(25, 7, 135);
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type="checkbox"] {
  border-radius: 0;
}

.mr-4 {
  margin-right: 1rem;
}

.h-\[26px\] {
  height: 26px;
}

.w-\[26px\] {
  width: 26px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.bg-\[length\:20px_20px\] {
  background-size: 20px 20px;
}

.bg-center {
  background-position: 50%;
}

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

:is([dir="ltr"] form input[type="checkbox"]) {
  margin-right: 1.111rem;
}

[type="checkbox"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.checked\:bg-blue:checked {
  --tw-bg-opacity: 1;
  background-color: #000;
}

.checked\:ring-0:checked {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.checked\:ring-offset-0:checked {
  --tw-ring-offset-width: 0px;
}

.block {
  display: block;
}

.pr-2 {
  padding-right: 0.5rem;
}

/* These were inline style tags. Uses id+class to override almost everything */
#style-XRAaD.style-XRAaD {
  color: rgb(230, 77, 77);
}

.style-cN6o9.style-cN6o9 {
  background-color: #000;
  padding: 1.5rem 2.75rem;
}
.style-cN6o9:hover {
  background-color: #000 !important;
}
#submit-button.disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}
</style>
